import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiFormControl-root .MuiFormLabel-root': {
            width: 'calc(100% - 54px)',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            '&.Mui-focused': {
                width: '100%',
                whiteSpace: 'initial',
            },
        },
    },
    searchInputRoot: {
        borderRadius: 9999,
        width: '100%',
        color: '#2E3092',
        fontSize: '31px',
        lineHeight: '41px',
        fontFamily: 'HartWell',
        '& input': {
            '&::placeholder': {
                color: '#2E3092',
                opacity: '1 !important',
                [theme.breakpoints.down('xs')]: {
                    fontSize: '14px',
                    padding: 0,
                },
            },
            [theme.breakpoints.down('xs')]: {
                height: '28px',
                padding: '0 !important',
                fontSize: '14px',
            },
        },
    },
    listContainer: {
        height: 60,
        paddingTop: 10,
        width: '100%',
        borderTop: '1px solid rgba(224, 224, 224, 1)',
    },
    firstListContainer: {
        height: 100,
    },
    topTitle: {
        display: 'block',
        width: '100%',
        height: '20px',
        paddingLeft: '13px',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        marginBottom: '8px',
    },
    imageContainer: {
        float: 'left',
        marginRight: 20,
    },
    img: {
        width: '50px',
        height: '50px',
    },
    title: {
        paddingTop: 10,
        paddingBottom: 5,
        textTransform: 'uppercase',
        fontSize: 10,
    },
    price: {
        fontWeight: 'bold',
        fontSize: 10,
    },
    listContainerCategory: {
        width: '100%',
        padding: 10,
        borderTop: '1px solid rgba(224, 224, 224, 1)',
    },
    topTitleCategory: {
        paddingLeft: 0,
    },
    breadcrumbs: {
        paddingBottom: 5,
        textTransform: 'uppercase',
        color: '#929292',
        fontSize: 10,
        fontStyle: 'italic',
    },
    titleCategory: {
        textTransform: 'uppercase',
        fontSize: 10,
    },
    searchContainer: {
        position: 'relative',
    },
}));

export default useStyles;
